import { format as formatDate } from 'date-fns';
import { nth } from 'lodash';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import { of } from 'rxjs';
import { filter, map, mergeAll, pluck, switchMap } from 'rxjs/operators';
import { RootActionType } from '../../rootActions';
import {
  clearAnnotationData,
  deleteAnnotation,
  nextAnnotableAnnotation,
  postponeAnnotation,
  startAnnotation,
} from '../annotation/actions';
import { DOCUMENTS_QUERY } from '../localStorage/actions';
import { throwInfo } from '../messages/actions';
import { fetchAnnotationStackFulfilled } from '../stack/actions';
import { onConfirmNavigateSelector } from '../user/selectors';
import { isActionOf, makeEpic } from '../utils';
import {
  deleteAnnotations,
  popAnnotationFromStack,
  postponeAnnotations,
} from './actions';

export const getFilenameForExport = (
  contentDisposition: string | null,
  queueName?: string | null,
  format?: string
) => {
  const matches =
    contentDisposition &&
    contentDisposition.match(/filename="((['"]).*?\2|[^";\n]*)/);

  return (
    nth(matches, 1) ||
    R.filter(
      ['export', queueName, formatDate(new Date(), 'yyyy-mm-dd')],
      R.isTruthy
    )
      .join('-')
      .replace(/[/\\*?"<>|:;,.%?=\s]+/g, '') // remove / \ * ? " > < | : ; , . % ? = and whitespace
      .concat(format ? `.${format}` : '')
  );
};

const deleteAnnotationsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(deleteAnnotations)),
    pluck('meta'),
    map(({ urls, skipRedirect, emailId }) =>
      urls.map(url => deleteAnnotation(url, skipRedirect, emailId))
    ),
    mergeAll()
  )
);

const postponeAnnotationsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(postponeAnnotations)),
    map(({ meta: { urls, skipRedirect } }) =>
      urls.map(url => postponeAnnotation(url, skipRedirect))
    ),
    mergeAll()
  )
);

const popAnnotationFromStackEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(popAnnotationFromStack)),
    switchMap(({ payload }) => {
      const documentListQuery = localStorage.getItem(DOCUMENTS_QUERY);
      const documentListUrl = documentListQuery
        ? `/documents?${documentListQuery}`
        : '/documents';
      return of<RootActionType>(
        fetchAnnotationStackFulfilled(
          state$.value.stack.filter(id => id !== payload.id)
        ),
        clearAnnotationData(),
        ...(onConfirmNavigateSelector(state$.value) === 'sidebarNavigation.next'
          ? [nextAnnotableAnnotation()]
          : onConfirmNavigateSelector(state$.value) === 'sidebarNavigation.stay'
            ? [startAnnotation(payload.id), throwInfo('annotationMoved')]
            : [replace(documentListUrl)])
      );
    })
  )
);

export default combineEpics(
  popAnnotationFromStackEpic,
  deleteAnnotationsEpic,
  postponeAnnotationsEpic
);
